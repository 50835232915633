<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6" align-self="end">
          <h3 class="title text-capitalize">
            {{ $t("payroll settings") }}
          </h3>
        </v-col>
      </v-row>
      <v-divider class="mb-4"></v-divider>
      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-1">
                  <v-icon left>mdi-scales</v-icon>{{ $t("day of the month") }}
                </div>

                <v-list-item-subtitle>
                  {{ $t("when a custom payroll template is generated") }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateOfPayrollTemplate"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        :rules="rules.emptyField"
                        @keypress="digits"
                      ></v-text-field>
                    </template>
                    <span>{{
                      $t(
                        "if not set, it will be calculated on the 1st day of month"
                      )
                    }}</span>
                  </v-tooltip></v-list-item-subtitle
                >
                <v-btn
                  small
                  dark
                  class="gradients"
                  :color="$store.state.secondaryColor"
                  @click="saveDateOfPayroll"
                  disabled
                  :loading="dateOfPayrollTemplateLoading"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize font-weight-bold">{{
                    $t("save")
                  }}</span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-list subheader dense>
            <div v-if="deductions.length != 0">
              <v-subheader class="text-overline black--text">{{
                $t("deductions")
              }}</v-subheader>
              <v-list-item
                v-for="deduction in deductions"
                :key="deduction.deductionName"
              >
                <v-list-item-content>
                  <v-layout row wrap class="px-3">
                    <v-flex xs12 sm12 md12 lg12>
                      <v-list-item-title
                        class="caption"
                        v-text="deduction.deductionName"
                      ></v-list-item-title>
                    </v-flex>
                    <v-spacer></v-spacer>
                  </v-layout>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-btn
                    class="mx-1"
                    color="red"
                    dark
                    x-small
                    @click="deleteDefaultDeduction(deduction.id)"
                  >
                    {{ $t("delete") }}
                  </v-btn>
                  <!-- <v-btn
                    class="mx-1"
                    :color="$store.state.primaryColor"
                    dark
                    x-small
                    @click="editDefaultDeduction(deduction.deductionName)"
                  >
                    edit
                  </v-btn> -->
                </v-list-item-icon>
              </v-list-item>
            </div>

            <div v-else>
              <span class="caption">{{ $t("no default deductions") }}</span>
            </div>
          </v-list>
        </v-flex>
        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-list subheader dense>
            <div v-if="earnings.length != 0">
              <v-subheader class="text-overline black--text">{{
                $t("earnings")
              }}</v-subheader>
              <v-list-item
                v-for="earning in earnings"
                :key="earning.earningName"
              >
                <v-list-item-content>
                  <v-layout row wrap class="px-3">
                    <v-flex xs12 sm12 md12 lg12>
                      <v-list-item-title
                        class="caption"
                        v-text="earning.earningsName"
                      ></v-list-item-title>
                    </v-flex>
                    <v-spacer></v-spacer>
                  </v-layout>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-btn
                    class="mx-1"
                    color="red"
                    dark
                    x-small
                    @click="deleteDefaultEarning(earning.id)"
                  >
                    {{ $t("delete") }}
                  </v-btn>
                  <!-- <v-btn
                    class="mx-1"
                    :color="$store.state.primaryColor"
                    dark
                    x-small
                    @click="editDefaultEarning(earning.earningsName)"
                  >
                    edit
                  </v-btn> -->
                </v-list-item-icon>
              </v-list-item>
            </div>

            <div v-else>
              <span class="caption">{{ $t("no default earnings") }}</span>
            </div>
          </v-list>
        </v-flex>
      </v-layout>

      <v-snackbar
        v-model="snackSuccessdateOfPayrollTemplate"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("payroll date updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessdateOfPayrollTemplate = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrordateOfPayrollTemplate"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not update payroll. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrordateOfPayrollTemplate = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorLoading"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not fetch data. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorLoading = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackSuccessDeductionDeleted"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("deduction deleted successfully") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessDeductionDeleted = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorDeductionDeleted"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not delete deduction. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorDeductionDeleted = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackSuccessEarningDeleted"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("earning deleted successfully") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessEarningDeleted = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorEarningDeleted"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not delete earning. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorEarningDeleted = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackLoading"
        top
        center
        color="info lighten-5 info--text text--darken-3"
        :timeout="12000"
      >
        <span>{{ $t("loading") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="info darken-3"
            @click="snackLoading = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="deleteEarningLoading"
        top
        center
        color="info lighten-5 info--text text--darken-3"
        :timeout="12000"
      >
        <span>{{ $t("deleting") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="info darken-3"
            @click="deleteEarningLoading = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="deleteDeductionLoading"
        top
        center
        color="info lighten-5 info--text text--darken-3"
        :timeout="12000"
      >
        <span>{{ $t("deleting...") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="info darken-3"
            @click="deleteDeductionLoading = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import db from "@/plugins/fb";
import { digits } from "@/plugins/functions";
export default {
  data() {
    return {
      dateOfPayrollTemplate: "",
      snackError: false,
      snackSuccess: false,
      snackLoading: true,
      earnings: [],
      deductions: [],
      snackErrorLoading: false,
      deleteDeductionLoading: false,
      deleteEarningLoading: false,
      buttonLoading: false,
      snackErrorDeductionDeleted: false,
      snackSuccessDeductionDeleted: false,
      snackErrorEarningDeleted: false,
      snackSuccessEarningDeleted: false,
      snackSuccessdateOfPayrollTemplate: false,
      snackErrordateOfPayrollTemplate: false,
      paybill: "",
      dateOfPayrollTemplateLoading: false,
    };
  },
  created() {
    this.currentUser = this.$store.state.currentUser;
    this.getSettings();
    this.getEarnings();
    this.getDeductions();
  },

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  methods: {
    digits,
    getSettings() {
      this.snackLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.dateOfPayrollTemplate = snaps.data().dateOfPayrollTemplate;
            this.snackLoading = false;
          } else {
            this.dateOfPayrollTemplate = 1;

            this.snackLoading = false;
          }
        })
        .catch(() => {
          this.snackErrorLoading = true;
        });
      // ######
    },
    getEarnings() {
      this.earnings = [];
      if (this.selected) {
        db.collection("earnings")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.earnings.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("earnings")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.earnings.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    getDeductions() {
      this.deductions = [];
      if (this.selected) {
        db.collection("deductions")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.deductions.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            console.log("deductions");
            console.log(this.deductions);
            this.loading = false;
          });
      } else {
        db.collection("deductions")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.deductions.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            console.log("deductions");
            console.log(this.deductions);
            this.loading = false;
          });
      }
    },
    saveDateOfPayroll() {
      this.dateOfPayrollTemplateLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          dateOfPayrollTemplate: this.dateOfPayrollTemplate,
        })
        .then(() => {
          this.dateOfPayrollTemplateLoading = false;
          this.snackSuccessdateOfPayrollTemplate = true;
          this.getSettings();
        })
        .catch(() => {
          this.dateOfPayrollTemplateLoading = false;
          this.snackErrordateOfPayrollTemplate = true;
        });
    },
    //delete deduction
    deleteDefaultDeduction(val) {
      this.deleteDeductionLoading = true;
      db.collection("deductions")
        .doc(val)
        .delete()
        .then(() => {
          this.deleteDeductionLoading = false;
          this.snackSuccessDeductionDeleted = true;
          this.getDeductions();
        })
        .catch(() => {
          this.deleteDeductionLoading = false;
          this.snackErrorDeductionDeleted = true;
        });
    },
    deleteDefaultEarning(val) {
      this.deleteEarningLoading = true;
      db.collection("earnings")
        .doc(val)
        .delete()
        .then(() => {
          this.deleteEarningLoading = false;
          this.snackSuccessEarningDeleted = true;
          this.getEarnings();
        })
        .catch(() => {
          this.deleteEarningLoading = false;
          this.snackErrorEarningDeleted = true;
        });
    },
    editDefaultDeduction(val) {
      console.log(val);
    },
    editDefaultEarning(val) {
      console.log(val);
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },
  },
};
</script>
